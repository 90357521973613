/**
 * 用户留言js
 */

import axios from "@/resources/js/request";
import da from "element-ui/src/locale/lang/da";
const request = axios.request;
/**
 * 获取留言信息数据
 * @returns {Promise<unknown>}
 */
export const getLeave = (data) => {
  return request.doPost('/leave/getLeave', data);
}

/**
 * 获取留言信息数据
 * @returns {Promise<unknown>}
 */
export const getCsServiceLeave = (data) => {
  return request.doPost('/leave/getCsServiceLeave', data);
}

/**
 * 标记留言
 * @returns {Promise<unknown>}
 */
export const dealWithLeave = (data) => {
  return request.doPost('/leave/dealWithLeave', data);
}
/**
 * 留言回答
 * @returns {Promise<unknown>}
 */
export const replyLeave = (data) => {
  return request.doPost('/leave/replyLeave', data);
}

export const getHandlerContent = (params) =>{
  return request.doGet('/leave/getHandlerContent',params)
}

