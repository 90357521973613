<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 10px">
      <el-input v-model="listQuery.projname" placeholder="项目名" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />
      <el-select v-model="listQuery.status" placeholder="状态" clearable style="width: 90px" class="filter-item">
        <template v-for="status in statusStatus">
          <el-option :label="status.name" :value="status.value"></el-option>
        </template>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
    </div>

    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="ID" sortable="custom" align="center" width="80">
        <template slot-scope="{row}">
          <span>{{ row.lmid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称" width="150px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.projname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="留言内容" width="300px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column label="留言状态" width="100px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.status | typeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="留言者" width="180px" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.clientid}}</span>
        </template>
      </el-table-column>
      <el-table-column label="留言者电话" width="120px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.mobile}}</span>
        </template>
      </el-table-column>
      <el-table-column label="留言者QQ" width="110px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.qq}}</span>
        </template>
      </el-table-column>
      <el-table-column label="留言者邮箱" width="110px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.email}}</span>
        </template>
      </el-table-column>
      <el-table-column label="IP地址" width="150px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.ip}}</span>
        </template>
      </el-table-column>
      <el-table-column label="浏览器类型" width="110px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.browserType}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="180">
        <template slot-scope="{row}">
          <span>{{ row.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="处理时间" align="center" class-name="status-col" width="180">
        <template slot-scope="{row}">
          <span :v-if="row.dealtime">{{ row.dealtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center"  fixed="right" width="280" class-name="small-padding fixed-width">
        <template slot-scope="{row,$index}">
          <el-button type="primary" v-if="row.status !='3'" size="mini" @click="handleReply(row)">
            回复
          </el-button>
          <el-button v-if="row.status=='0'" size="mini" type="success" @click="handleModifyStatus(row,'1')">
            标记处理
          </el-button>
          <el-button v-if="row.status=='1'" size="mini" type="primary" @click="handleModifyStatus(row,'2')">
            标记完成
          </el-button>
          <el-button size="mini" v-if="row.status !='3'" type="danger" @click="handleModifyStatus(row,'3')">
            标记废除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 500px; margin-left:50px;">
        <el-form-item label="留言回复" prop="content">
          <el-input v-model="temp.content" type="text" placeholder="请输入回复内容"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='reply'?replyData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import {getLeave,dealWithLeave,replyLeave} from "@/apis/leave";
  import { parseTime } from '@/utils'
  const statusOptions = [
    { key: '0', display_name: '未处理' },
    { key: '1', display_name: '处理中' },
    { key: '2', display_name: '已处理' },
    { key: '3', display_name: '已废除' },
  ]
  const calendarTypeKeyValue = statusOptions.reduce((acc, cur) => {
    acc[cur.key] = cur.display_name
    return acc
  }, {})
  export default {
    name: 'projectinfo',
    components: {
      pagination: () => import('@/components/common/PagingPlug'),
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'info',
          deleted: 'danger'
        }
        return statusMap[status]
      },
      typeFilter(type) {
        return calendarTypeKeyValue[type]
      },
      parseTime:parseTime
    },
    data() {
      return {
        tableKey: 0,
        list: null,
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          projname:'',
          status:'',
          auid:null
        },
        statusOptions: ['published', 'draft', 'deleted'],
        showReviewer: false,
        temp: {
          lmid: null,
          csid: '',
          content: ''
        },
        dialogFormVisible: false,
        dialogStatus: '',
        textMap: {
          reply: '留言回复'
        },
        dialogPvVisible: false,
        pvData: [],
        rules: {
          content: [{ required: true, message: '请输入回复内容', trigger: 'change' }]
        },
        downloadLoading: false
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        this.listQuery.auid=this.user.userId
        getLeave(this.listQuery).then(res => {
        const {data = [], count = 0} = res;
        this.list=data;
        this.total=count;
        this.listLoading = false;
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      handleModifyStatus(row, status) {
        this.temp = Object.assign({}, row)
        var lmid=this.temp.lmid;
        var auid=this.user.userId;
        dealWithLeave({lmid,status,auid}).then(res =>{
          this.$message.success(res.retDesc);
          row.status = status
        })
      },
      formatJson(filterVal) {
        return this.list.map(v => filterVal.map(j => {
          if (j === 'timestamp') {
            return parseTime(v[j])
          } else {
            return v[j]
          }
        }))
      },handleReply(row) {
        this.temp = Object.assign({}, row)
        this.temp.content='';
        this.dialogStatus = 'reply'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },replyData(){
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            if (this.temp.status != 0){
              this.temp.csid=this.user.userId
              replyLeave(this.temp).then(res =>{
                this.$message.success(res.retDesc);
                this.dialogFormVisible=false;
              });
            }else {
              this.$message.error("请先标记处理");
            }
          }
        })
      }
    },computed:{
    statusStatus() {
      return [
        {name: '全部', value: null},
        {name: '未处理', value: 0},
        {name: '处理中', value: 1},
        {name: '已处理', value: 2},
        {name: '已废除', value: 3}
      ]
    },
  }
  }
</script>

<style scoped>
.filter-item {
  margin-left:5px;
}
</style>
<style>
  .el-tooltip__popper {
    max-width: 800px;
  }
</style>
